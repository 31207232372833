<template>
  <div class="test_group_warp">
    <div class="left">
      <LeftController @initList="initData" />
    </div>
    <div class="right">
      <TopCard ref="TopCardRef"
               :text="titleText"
               :showBack="false" />
      <div class="list">
        <div class="warp">
          <el-row v-for="(item,index) in tableData"
                  :key="index">
            <el-col :span="6">
              <div class="paper_name">
                <div class="text">
                  <div>
                    {{item.template_name}}
                  </div>
                  <div>
                    试卷ID:
                    {{item.user_paper_id}}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="score">
                {{moduleList[item.module_id]}}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="score" v-if="item.correct_type == 2">
                自评试卷
              </div>
               <div class="score" v-else>
                老师改卷
              </div>
            </el-col>
            <el-col :span="3">
              <div class="score">
                总分：{{item.paper_score}}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="score">
                {{item.paper_time}}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="btn_warp">
                <div class="btn"
                     @click="doPaper(item)">
                  继续做卷
                </div>
                <div class="del_btn"
                     @click="delPaper(item)">
                  删除
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftController from '@/views/components/left_controller.vue'
import TopCard from '@/views/components/top_card/index.vue'
import { getNnotPress, delPaper } from '@/api/my_paper.js'

export default {
  data () {
    return {
      tableData: [],
      subject_id: null,
      moduleList: {
        1: '自组组卷',
        // 2: '考点组卷',
        3: '整卷组卷',
        4: '考点组卷',
        5: '章节组卷'
      },
      titleText: ''
    }
  },
  components: {
    LeftController, TopCard
  },
  mounted () {
    this.$removePaperInfo()
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    if (userInfo.edu_grade_id == 1) {
      this.titleText = "备考进度"
    } else {
      this.titleText = "学习进度"
    }
  },
  methods: {
    async initData (id) {
      this.subject_id = id
      let params = {
        subject_id: id,
        status: 0
      }
      const { data } = await getNnotPress(params)
      this.tableData = data.volist
      
    },
    doPaper (item) {
      this.$router.push('/doPaper?type=2&form=' + JSON.stringify({ user_paper_id: item.user_paper_id }))
    },
    delPaper (item) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let params = {
          user_paper_id: item.user_paper_id
        }
        await delPaper(params)
        this.$message.success('删除成功')
        this.page = 1
        this.initData(this.subject_id)
      }).catch(() => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.test_group_warp {
  overflow: hidden;
  display: flex;
  .left {
    width: 100rem;
  }
  .right {
    width: calc(100% - 100rem);
    background: #f6f6f6;
    .tabs {
      margin-top: 22rem;
      display: flex;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18rem;
      .tab {
        cursor: pointer;
        width: 260rem;
        height: 54rem;
        background: #2196f3;
        border-radius: 30rem;
        color: #ffffff;
        font-size: 24rem;
        font-weight: 800;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .list {
      width: 100%;
      max-height: calc(100vh - 60rem - 40rem);
      -ms-overflow-style: none;
      background: white;
      overflow-y: auto;
      overflow: -moz-scrollbars-none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .el-row {
        // height: 110rem;
        border-bottom: 1rem solid #f1f1f1;
        &:hover {
          background: rgba(33, 150, 243, 0.1);
        }
      }
      // div {
      //   height: 80rem;
      //   line-height: 80rem;
      // }
      .paper_name {
        margin-left: 50rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 110rem;
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100rem;
          height: 70rem;
          background: #6cb9f5;
          border-radius: 5rem;
          img {
            width: 34rem;
            height: 34rem;
          }
        }
        .text {
          height: 70rem;
          // line-height: 70rem;
          margin-left: 20rem;
          div {
            &:nth-child(1) {
              font-size: 20rem;
              font-weight: bold;
              color: #000000;
            }
            &:nth-child(2) {
              margin-top: 10rem;
              font-weight: 500;
              font-size: 20rem;
              color: #333333;
            }
          }
        }
      }
      .score {
        font-weight: 500;
        color: #333333;
        font-size: 20rem;
        height: 110rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .btn_warp {
        display: flex;
        height: 110rem;
        align-items: center;
        justify-content: flex-end;
        .btn,
        .del_btn {
          width: 120rem;
          height: 44rem;
          margin-right: 20rem;
          border-radius: 5rem;
          font-size: 20rem;
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .btn {
          color: #ffffff;
          background: #2196f3;
          margin-bottom: 0rem;
        }
        .del_btn {
          color: rgb(254, 131, 116);
          border: 1px solid rgb(254, 131, 116);
          &:hover {
            background: #fffafa;
            color: #f35e5e;
          }
        }
      }
    }
  }
}
</style>